import { gql } from '@apollo/client'
import Button from '@gameonsports/components/cjs/Button'
import Loader from '@gameonsports/components/cjs/Loader'
import Notification from '@gameonsports/components/cjs/Notification'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import { Text } from '@gameonsports/components/cjs/TextV3'
import WizardStepper, {
  WizardStep,
} from '@gameonsports/components/cjs/WizardStepper'
import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import BoxContainer from '../../components/BoxContainer'
import FeedbackAndSupport from '../../components/FeedbackAndSupport'
import FullWidthBlock from '../../components/FullWidthBlock'
import OrganisationPageWrapper from '../../components/OrganisationPageWrapper'
import OrgLogoName from '../../components/OrgLogoName'
import SeasonStatusList from '../../components/SeasonStatusList'
import SectionContainer from '../../components/SectionContainer'
import { SEASON_STATUS_TYPES } from '../../constants/seasonStatusTypes'
import {
  DiscoverCompetitionsDiscoverCompetitions,
  DiscoverCompetitionsOrganisation,
  DiscoverSeasonStatusType,
  OrganisationDetailsFragmentDoc,
  OrganisationType,
  TenantContactRolesConfigurationFragmentDoc,
  useDiscoverCompetitionsQuery,
} from '../../generated/graphql'
import {
  useLocationWithState,
  useNavigateWithState,
} from '../../hooks/useRouterWithState'
import { setOrganisationContacts } from '../../utils/organisation'
import { media } from '../../utils/styled-components-utils'

export const DISCOVER_COMPETITIONS = gql`
  query discoverCompetitions($organisationID: ID!, $organisationCode: String!) {
    discoverCompetitions(organisationID: $organisationID) {
      id
      name
      seasons(organisationID: $organisationID) {
        id
        name
        startDate
        endDate
        status {
          name
          value
        }
      }
      organisation {
        id
        name
        logo {
          sizes {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
    discoverOrganisation(code: $organisationCode) {
      ...OrganisationDetails
    }
    tenantConfiguration {
      label
      sport {
        name
      }
      ...TenantContactRolesConfiguration
    }
  }
  ${OrganisationDetailsFragmentDoc}
  ${TenantContactRolesConfigurationFragmentDoc}
`

const EmptyBox = styled(BoxContainer)`
  display: grid;
  grid-gap: 1rem;

  ${media.tablet`
    grid-gap: 2.125rem;
  `}
`

const CompetitionsBox = styled(BoxContainer)`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 2rem;

    ${media.tablet`
      margin-top: 3.5rem;
    `}
  }

  @supports (display: grid) {
    display: grid;
    grid-gap: 2rem;

    > * + * {
      margin-top: initial;

      ${media.tablet`
        margin-top: initial;
      `}
    }

    ${media.tablet`
      grid-gap: 3.5rem;
    `}
  }
`

const StyledFullWidthBlock = styled(FullWidthBlock)`
  display: grid;
  grid-gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const StyledFeeback = styled(FeedbackAndSupport)`
  margin-top: 4rem;
`

const AssociationLogoName = styled(OrgLogoName)`
  padding: 0;

  .organisation-name {
    font-size: 1rem;
    font-weight: 700;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  ${media.tablet`
    .organisation-name {
      font-size: 1.25rem;
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  `}
`

const AssociationSeasonsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;

  ${media.tablet`
    grid-gap: 2rem;
  `}
`

const StackWrap = styled(Stack)`
  flex-wrap: wrap;
`

interface OrganisationCompetition {
  organisation: DiscoverCompetitionsOrganisation
  competitions: DiscoverCompetitionsDiscoverCompetitions[]
}

const SeasonList: React.FC<
  RouteComponentProps<{
    organisationId: string
    tenant: string
  }>
> = ({ organisationId, tenant }) => {
  const location = useLocationWithState()
  const navigate = useNavigateWithState()

  const [statusFilter, setStatusFilter] = useState<DiscoverSeasonStatusType[]>(
    [],
  )

  const isFiltered = statusFilter.length < SEASON_STATUS_TYPES.length

  const { data, loading, error } = useDiscoverCompetitionsQuery({
    variables: {
      organisationID: String(organisationId),
      organisationCode: String(organisationId),
    },
    onError: () => null,
    onCompleted: data => {
      // Redirect to `/register` if the global flag is set (ie. coming from
      // organisation search), and the org has no competitions. NOTE: clear the
      // flag after the redirect to prevent it happening again when selecting
      // the competitions tab (which would continue to redirect back to /register
      // in an infinite loop).
      if (
        location.state?.redirectToRegistrationTab &&
        data.discoverCompetitions.length < 1
      ) {
        navigate(`${location.pathname}/register`, {
          replace: true,
          state: { redirectToRegistrationTab: false },
        })
      }

      const selectedStatusFilters = SEASON_STATUS_TYPES.reduce<
        DiscoverSeasonStatusType[]
      >((acc, curr) => {
        // Initialise the Completed filter to disabled for all tenants
        if (curr.value === DiscoverSeasonStatusType.Completed) {
          return acc
        }
        return [...acc, curr.value]
      }, [])

      setStatusFilter(selectedStatusFilters)
    },
  })

  if (loading) {
    return <Loader />
  }

  if (
    error ||
    !data ||
    !data.discoverCompetitions ||
    !data.discoverOrganisation ||
    !data.tenantConfiguration
  ) {
    return (
      <StyledFullWidthBlock>
        <Notification variant="error">
          There was an error retrieving the competitions for this organisation.
          Please try again later.
        </Notification>
      </StyledFullWidthBlock>
    )
  }

  const {
    discoverOrganisation,
    discoverCompetitions: competitions,
    tenantConfiguration: { contactRoles },
  } = data

  const organisation = setOrganisationContacts(
    discoverOrganisation,
    contactRoles,
  )

  const isClub = organisation.type === OrganisationType.Club

  const organisationCompetitions = competitions.reduce((acc, curr) => {
    const existingOrgIndex = acc.findIndex(
      a => a.organisation.id === curr.organisation.id,
    )

    if (existingOrgIndex > -1) {
      return [
        ...acc.slice(0, existingOrgIndex),
        {
          ...acc[existingOrgIndex],
          competitions: [...acc[existingOrgIndex].competitions, curr],
        },
        ...acc.slice(existingOrgIndex + 1),
      ]
    }

    return [
      ...acc,
      {
        organisation: curr.organisation,
        competitions: [curr],
      },
    ]
  }, [] as OrganisationCompetition[])

  const filteredOrganisationCompetitions = isFiltered
    ? organisationCompetitions.filter(orgComp =>
        orgComp.competitions.some(comp =>
          comp.seasons.some(season =>
            statusFilter.includes(season.status.value),
          ),
        ),
      )
    : organisationCompetitions

  return (
    <OrganisationPageWrapper
      organisation={organisation}
      tenant={String(tenant)}
    >
      <Helmet
        title={`${organisation.name}`}
        meta={[
          {
            name: 'description',
            content: `View ${organisation.name}'s competitions, seasons and contact details`,
          },
        ]}
      />
      <FullWidthBlock>
        <SectionContainer noXPadding>
          {competitions.length === 0 && (
            <EmptyBox>
              <Text size="20" weight="700">
                No competitions available.
              </Text>
              <Notification variant="empty">
                There are currently no competitions available for this{' '}
                {isClub ? 'club' : 'association'}.
              </Notification>
            </EmptyBox>
          )}
          {competitions.length > 0 && (
            <>
              <WizardStepper>
                <WizardStep count={1} highlight>
                  Select a Season
                </WizardStep>
                <WizardStep count={2}>
                  Select a {isClub ? 'Team' : 'Grade'}
                </WizardStep>
              </WizardStepper>
              <CompetitionsBox>
                <Stack
                  direction={{ tablet: 'row' }}
                  justifyContent={{ tablet: 'space-between' }}
                  gap="m"
                >
                  <Text size="20" weight="700">
                    Competitions
                  </Text>
                  <StackWrap direction="row" gap="s">
                    {SEASON_STATUS_TYPES.map(status => {
                      const isActive = statusFilter.includes(status.value)
                      return (
                        <Button
                          size="small"
                          key={status.value}
                          variant={isActive ? 'primary' : 'quinary'}
                          aria-pressed={isActive}
                          onClick={() =>
                            setStatusFilter(
                              isActive
                                ? statusFilter.filter(s => s !== status.value)
                                : statusFilter.concat(status.value),
                            )
                          }
                        >
                          <Text weight="400">{status.name}</Text>
                        </Button>
                      )
                    })}
                  </StackWrap>
                </Stack>
                {isFiltered &&
                  filteredOrganisationCompetitions.length === 0 &&
                  (statusFilter.length === 1 ? (
                    <Notification variant="empty">
                      There are no{' '}
                      {SEASON_STATUS_TYPES.find(
                        t => t.value === statusFilter[0],
                      )?.name.toLowerCase()}{' '}
                      seasons available for this organisation.
                    </Notification>
                  ) : (
                    <Notification variant="empty">
                      There are no seasons available for the selected filters.
                    </Notification>
                  ))}

                {filteredOrganisationCompetitions.map(orgComp => (
                  <AssociationSeasonsContainer
                    data-testid={`season-org-${orgComp.organisation.id}`}
                    key={orgComp.organisation.id}
                  >
                    {isClub && (
                      <AssociationLogoName
                        name={orgComp.organisation.name}
                        logo={orgComp.organisation.logo}
                      />
                    )}
                    {orgComp.competitions.map(competition => {
                      if (
                        isFiltered &&
                        competition.seasons.every(
                          s => !statusFilter.includes(s.status.value),
                        )
                      ) {
                        return null
                      }

                      return (
                        <SeasonStatusList
                          key={competition.id}
                          name={competition.name}
                          seasons={competition.seasons}
                          organisation={organisation}
                          tenant={String(tenant)}
                          statusFilter={statusFilter}
                        />
                      )
                    })}
                  </AssociationSeasonsContainer>
                ))}
              </CompetitionsBox>
            </>
          )}
        </SectionContainer>

        <StyledFeeback />
      </FullWidthBlock>
    </OrganisationPageWrapper>
  )
}

export default SeasonList
